<script>

    import appConfig from "@/app.config";

    /**
     * Projects-grid component
     */
    export default {
        page: {
            title: "TOS",
            meta: [{name: "description", content: appConfig.description}]
        },
        name: 'TOS',
        mounted () {
            window.scrollTo(0, 0)
        }


    };
</script>

<template>
    <div>
        <section class="container py-landing text-center">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-9"><h1 class=" font-weight-bold color-white"> {{$t('tos.header')}}</h1></div>
                </div>
        </section>
        <section class="general bg-color-blue-nav color-white">
            <div class="container py-landing">
                <div class="general-wrapper">
                    <div class="row justify-content-center">
                        <div class="col-12 font-size-15">
                           <h4 class="my-3"><strong>1. {{$t('tos.part1.t')}}</strong></h4>
                            <p>{{$t('tos.part1.p')}}</p>
                            <h4 class="my-3"><strong>2. {{$t('tos.part2.t')}}</strong></h4>
                            <p> {{$t('tos.part2.p')}}</p>
                                <ul class="m-4">
                                    <li>{{$t('tos.part2.l1')}}</li>
                                    <li>{{$t('tos.part2.l2')}}</li>
                                    <li>{{$t('tos.part2.l3')}}</li>
                                    <li>{{$t('tos.part2.l4')}}</li>
                                </ul>
                            <p>{{$t('tos.part2.p2')}}</p>
                            <h4 class="my-3"><strong>3. {{$t('tos.part3.t')}}</strong></h4>
                            <p>{{$t('tos.part3.p1')}} </p>
                            <p>{{$t('tos.part3.p2')}} </p>
                            <p>{{$t('tos.part3.p3')}} </p>
                            <p>{{$t('tos.part3.p4')}} </p>
                            <p>{{$t('tos.part3.p5')}} </p>
                            <p>{{$t('tos.part3.p6')}} </p>
                            <h4 class="my-3"><strong>4. {{$t('tos.part4.t')}} </strong></h4>
                            <p>{{$t('tos.part4.p')}}</p>
                      <ul class="m-4">
                      <li>{{$t('tos.part4.l1')}}</li>
                      <li>{{$t('tos.part4.l2')}}</li>
                      <li>{{$t('tos.part4.l3')}}</li>
                      <li>{{$t('tos.part4.l4')}}</li>
                      <li>{{$t('tos.part4.l5')}}</li>
                      <li>{{$t('tos.part4.l6')}}</li>
                      <li>{{$t('tos.part4.l7')}}</li>
                      <li>{{$t('tos.part4.l8')}}</li>
                      <li>{{$t('tos.part4.l9')}}</li>
                      <li>{{$t('tos.part4.l10')}}</li>
                      <li>{{$t('tos.part4.l11')}}</li>
                      <li>{{$t('tos.part4.l12')}}</li>
                      </ul>
                            <h4 class="my-3"><strong>5. {{$t('tos.part5.t')}}</strong></h4>
                            <p>{{$t('tos.part5.p')}}</p>
                            <h4 class="my-3"><strong>6. {{$t('tos.part6.t')}}</strong></h4>
                            <p>{{$t('tos.part6.p')}}</p>
                            <h4 class="my-3"><strong>7. {{$t('tos.part7.t')}}</strong></h4>
                            <p>{{$t('tos.part7.p1')}}</p>
                            <p>{{$t('tos.part7.p2')}}</p>
                            <p>{{$t('tos.part7.p3')}}</p>
                            <p>{{$t('tos.part7.p4')}}</p>
                            <p>{{$t('tos.part7.p5')}}</p>
                            <p>{{$t('tos.part7.p6')}}</p>
                            <p>{{$t('tos.part7.p7')}}</p>
                            <p>{{$t('tos.part7.p8')}}</p>
                            <p>{{$t('tos.part7.p9')}}</p>
                            <p>{{$t('tos.part7.p10')}}</p>
                            <h4 class="my-3"><strong>8. {{$t('tos.part8.t')}}</strong></h4>
                            <p>{{$t('tos.part8.p')}}</p>
                            <h4 class="my-3"><strong>9. {{$t('tos.part9.t')}}</strong></h4>
                            <p>{{$t('tos.part9.p1')}}</p>
                            <p>{{$t('tos.part9.p2')}}</p>
                            <p>{{$t('tos.part9.p3')}}</p>
                            <p>{{$t('tos.part9.p4')}}</p>
                            <p>{{$t('tos.part9.p5')}}</p>
                            <p>{{$t('tos.part9.p6')}}</p>
                            <p>{{$t('tos.part9.p7')}}</p>
                       <ul class="m-4">
                           <li>{{$t('tos.part9.l1')}}</li>
                           <li>{{$t('tos.part9.l2')}}</li>
                           <li>{{$t('tos.part9.l3')}}</li>
                           <li>{{$t('tos.part9.l4')}}</li>
                           <li>{{$t('tos.part9.l5')}}</li>
                           <li>{{$t('tos.part9.l6')}}</li>
                       </ul>
                            <p>{{$t('tos.part9.p8')}}</p>
                            <p>{{$t('tos.part9.p9')}}</p>
                            <p>{{$t('tos.part9.p10')}}</p>
                            <h4 class="my-3"><strong>10. {{$t('tos.part10.t')}}</strong></h4>
                            <p>{{$t('tos.part10.p')}}</p>
                            <h4 class="my-3"><strong>11. {{$t('tos.part11.t')}}</strong></h4>
                            <p>{{$t('tos.part11.p1')}}</p>
                            <p>{{$t('tos.part11.p2')}}</p>
                            <p>{{$t('tos.part11.p3')}}</p>
                            <p>{{$t('tos.part11.p4')}}</p>
                            <ol class="m-4" style="list-style-type: lower-alpha;">
                                <li>{{$t('tos.part11.l1')}}</li>
                                <li>{{$t('tos.part11.l2')}}</li>
                            </ol>
                            <p>{{$t('tos.part11.p5')}}</p>
                            <p>{{$t('tos.part11.p6')}}</p>
                            <p>{{$t('tos.part11.p7')}}</p>
                            <p>{{$t('tos.part11.p8')}}</p>
                            <p>{{$t('tos.part11.p9')}}</p>
                            <h4 class="my-3"><strong>12. {{$t('tos.part12.t')}}</strong></h4>
                            <p>{{$t('tos.part12.p')}}</p>
                            <h4 class="my-3"><strong>13. {{$t('tos.part13.t')}}</strong></h4>
                            <p>{{$t('tos.part13.p')}}<router-link to="/privacypolicy"> {{$t('tos.part13.a')}} </router-link></p>
                            <h4 class="my-3"><strong>14. {{$t('tos.part14.t')}}</strong></h4>
                            <p>{{$t('tos.part14.p1')}}</p>
                           <p class="text-white font-weight-bold my-4">
                               * {{$t('tos.part14.p2')}}
                           </p>
                            <p class="text-white font-weight-bold my-4">
                                ** {{$t('tos.part14.p3')}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- end ro w -->
    </div>
</template>
<style scoped>
    .py-landing {
        padding-top: 5rem;
    }
    section.general .general-wrapper {
        max-width: 43.5rem;
        margin: 0 auto;
    }
    p {
        color: #a4b1cd;
        line-height: 30px;
    }
   li{
       margin-bottom: 8px;
   }

</style>